
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
  },

  data() {
    return {
      tourItems: "",
      currentPage: Number(this.$route.params.page),
      seriesAry: {},
      timetableAry: {},
      maxPage: 1,
    };
  },
  mounted () {
    if (this.$route.name == 'tourlist') {
      this.getData(this.$route.params.page);
    } else if (this.$route.name == 'tourSeries') {
      this.getEventData();
    } else if (this.$route.name == 'tourTimetable') {
      this.getTimetable();
    }
  },
  watch: {
    currentPage() {
      this.getData(this.currentPage);
      this.$router.push({
        name: "tourlist",
        params: { page: this.currentPage },
      });
    },
    $route(to, from) {
      if (to.name == "tourlist") {
        this.currentPage = Number(this.$route.params.page);
      } else if (to.name == "tourSeries"){
        this.getEventData();
      } else if (to.name == "tourTimetable"){
        this.getTimetable();
      }
    },
  },
  methods: {
    getData(page) {
      ApiService.post(
        process.env.VUE_APP_API_URL + "&mode=pub&tab=listtour&action=tourlist&page=" + page,
        {}
      ).then((response) => {
        this.tourItems = response.data.result;
        this.maxPage = response.data.maxPage;
      });
    },
    getEventData() {
      ApiService.post(
        process.env.VUE_APP_API_URL + "&mode=pub&tab=listtour&action=eventlist&sport=" + this.$route.params.sport + "&region=" + this.$route.params.region,
        {}
      ).then((response) => {
        this.seriesAry = response.data.result;
      });
    },
    getTimetable() {
      ApiService.post(
        process.env.VUE_APP_API_URL + "&mode=pub&tab=listtour&action=timetable",
        {}
      ).then((response) => {
        this.timetableAry = response.data.result;
      });
    },
  },
});
